import React, { useContext, useState } from "react";

const SignUp = () => {
  
  return (
    <div className="w-full">
      signup
    </div>
  );
};

export default SignUp;